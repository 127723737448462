import React from "react";
import PropTypes from "prop-types";

//styles
import { GridWrapper } from "./styles";

const Grid = ({ children, hMargin, vAlign, ...restProps }) => {
	return (
		<GridWrapper hMargin={hMargin} vAlign={vAlign} {...restProps}>
			{children}
		</GridWrapper>
	);
};

Grid.defaultProps = {
	hMargin: [96, 24],
	vAlign: "initial",
};

Grid.propTypes = {
	hMargin: (props, propName) => {
		if (
			!Array.isArray(props[propName]) ||
			props[propName].length != 2 ||
			!props[propName].every(Number.isInteger)
		) {
			return new Error(
				`${propName} needs to be an array of two numbers - first one for desktop margin and second for mobile`,
			);
		}

		return null;
	},
	vAlign: PropTypes.oneOf([
		"flex-end",
		"flex-start",
		"self-end",
		"self-start",
		"start",
		"end",
		"center",
		"inherit",
		"initial",
		"normal",
		"stretch",
		"revert",
		"unset",
		"baseline",
	]), // defines row alignment
};
export default Grid;
